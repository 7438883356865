import React from 'react'
import { ApolloProvider } from '@apollo/client'

import { apolloClient } from './apollo-client'
import { AppContextProvider } from './app-context'
import { Layout } from 'components'

export function AppProvider(props) {
  const { children, ...rest } = props
  return (
    <AppContextProvider>
      <ApolloProvider client={apolloClient}>
        <Layout {...rest}>{children}</Layout>
      </ApolloProvider>
    </AppContextProvider>
  )
}
