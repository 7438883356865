/** @jsx jsx */
import { jsx, Text } from 'theme-ui'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text as="p">{children}</Text>,
  },
}

export const renderContent = (document) =>
  documentToReactComponents(document, options)
