import { useQuery } from '@apollo/client'
import { dateFormat } from 'utils/dates'
import { GET_DISABLED_DAYS } from 'queries/rooms'
import { addMonths, subDays } from 'date-fns/fp'

export default function useDisabledDays(identifier) {
  const { data } = useQuery(GET_DISABLED_DAYS, {
    variables: {
      identifier,
      startDate: dateFormat(subDays(2, Date.now())),
      endDate: dateFormat(addMonths(5, Date.now())),
    },
    skip: !identifier,
  })

  return data?.room?.disabledDays ?? []
}
