/** @jsx jsx */
import { jsx, Grid, Flex, Box, Button, Text, Heading } from 'theme-ui'
import Image from 'gatsby-image'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Fragment } from 'react'
import { useMeasure } from 'react-use'

export function AttractionMap(props) {
  const { showInfo } = props
  const [boxRef, { height }] = useMeasure()

  const imagesQuery = useStaticQuery(graphql`
    query AttractionMapImages {
      mapLarge: file(relativePath: { eq: "map-large-v4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mobileMap: file(relativePath: { eq: "map-mobile-v4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Fragment>
      <Box
        ref={boxRef}
        style={{
          position: 'relative',
        }}
        sx={{ display: ['block', null, 'none'] }}
      >
        <Image
          fluid={imagesQuery.mobileMap.childImageSharp.fluid}
          backgroundColor={`#040e18`}
        />
      </Box>

      <Grid columns="1fr" rows="1fr" sx={{ justifyContent: 'center' }}>
        <Grid
          sx={{
            gridRow: '1/1',
            gridColumn: '1/1',
            position: 'relative',
            zIndex: 1,
            display: ['none', null, 'grid'],
          }}
        >
          <Image
            fluid={imagesQuery.mapLarge.childImageSharp.fluid}
            backgroundColor={`#040e18`}
          />
        </Grid>

        {showInfo && (
          <Flex
            sx={{
              gridRow: [null, null, null, '1/1'],
              gridColumn: [null, null, null, '1/1'],
              position: 'relative',
              width: '100%',
              zIndex: 2,
            }}
          >
            <Box ml="auto" mr={[null, null, null, 'xl']} my="auto">
              <Box sx={{ maxWidth: [null, null, null, 560] }}>
                <Box
                  px={['m', null, 'l']}
                  py="l"
                  bg={[null, null, 'white.100']}
                  sx={{ boxShadow: [null, null, null, 'l'] }}
                >
                  <Heading variant="heading.l" mb="m">
                    A perfectly placed pub to explore the Three Peaks and
                    Yorkshire Dales
                  </Heading>
                  <Text as="p" mb="l">
                    Situated in an imposing and rural position in the heart of
                    the Yorkshire Dales, The Station Inn provides a perfect base
                    for a host of activities.
                  </Text>
                  <Box
                    sx={{
                      display: ['block', null, 'flex'],
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      as={Link}
                      to="/nearby"
                      variant="secondary"
                      mr="l"
                      mb={['m', null, 0]}
                    >
                      View nearby places
                    </Button>
                    <Text
                      variant="subhead"
                      as="a"
                      href="https://goo.gl/maps/iRCwPZSKwovCqsGG6"
                      target="_blank"
                      sx={{ textDecoration: 'none', color: 'black.100' }}
                    >
                      Find us on Google maps
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Flex>
        )}
      </Grid>
    </Fragment>
  )
}
