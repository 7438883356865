import { gql } from '@apollo/client'
import { ItemisedObjectFragment, ResourceFragment } from './fragments'

export const GET_ROOM = gql`
  query GetRoom(
    $identifier: String!
    $startDate: Date!
    $endDate: Date!
    $statuses: [String]
  ) {
    room: findResource(identifier: $identifier) {
      ...ResourceFragment
      firstSlot: firstAvailability(date: $startDate)
      lastSlot: lastAvailability(date: $endDate)
      disabledDays(startDate: $startDate, endDate: $endDate)
      availabilityGroups(
        startDate: $startDate
        endDate: $endDate
        statuses: $statuses
      ) {
        date
        fullDay
        availabilities {
          start
          end
        }
      }
      extras {
        id
        identifier
      }
    }
  }
  ${ResourceFragment}
`

export const GET_ROOM_EXTRAS = gql`
  query GetRoomExtras($identifier: String!) {
    room: findResource(identifier: $identifier) {
      ...ResourceFragment
      extras {
        id
        identifier
        title
        dailyCost(date: "2020-01-01")
        type
      }
    }
  }
  ${ResourceFragment}
`

export const CALCULATE_COST = gql`
  query GetRoomCost($booking: CalcInput!) {
    calculateCost(booking: $booking) {
      cost
    }
    calculateItemisedCost(booking: $booking) {
      totalCost
      totalExtrasCost
      totalResourcesCost
      resources {
        ...ItemisedObjectFragment
      }
      extras {
        ...ItemisedObjectFragment
      }
    }
  }
  ${ItemisedObjectFragment}
`

export const GET_DISABLED_DAYS = gql`
  query GetRoom($identifier: String!, $startDate: Date!, $endDate: Date!) {
    room: findResource(identifier: $identifier) {
      id
      disabledDays(startDate: $startDate, endDate: $endDate)
    }
  }
`

export const GET_ROOM_AVAILABILITY = gql`
  query findContinuouslyAvailableResources(
    $startDate: Date!
    $endDate: Date!
    $organisationId: ID!
  ) {
    resources: findContinuouslyAvailableResources(
      organisationId: $organisationId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      identifier
      description
      blockCost
      blockSize
      dailyCost
      firstAvailability
      lastAvailability
    }
  }
`
