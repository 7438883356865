/** @jsx jsx */
import { jsx, Box, Grid, Heading, Text, Divider, Flex } from 'theme-ui'
import { graphql, useStaticQuery } from 'gatsby'
import {
  compose,
  pathOr,
  isEmpty,
  reject,
  prepend,
  when,
  isNil,
  all,
  T,
  complement,
} from 'ramda'
import React, { useEffect } from 'react'
import { useLocation } from 'react-use'
import queryString from 'query-string'

import { useAppContext } from 'utils'
import { notEmptyOrNil } from 'utils/utils'

import useRoomAvailability from 'hooks/use-room-availability'
import useBookingCost from 'hooks/use-booking-cost'

import Calendar from './calendar'
import RoomAvailabilityButtons from './room-availability-buttons'
import RoomBookingInfo from './room-booking-info'
import RoomModal from './room-modal'

const rejectJordan = (room) => room.identifier !== 'unavailable'

export const CONTENTFUL_ROOMS = graphql`
  query RoomsQueryTwo {
    northRooms: contentfulRoomType(slug: { eq: "north-facing" }) {
      ...RoomTypeFragment
      rooms {
        ...RoomFragment
      }
    }
    southRooms: contentfulRoomType(slug: { eq: "south-facing" }) {
      ...RoomTypeFragment
      rooms {
        ...RoomFragment
      }
    }
    bunkhouses: contentfulRoomType(slug: { eq: "bunkhouses" }) {
      ...RoomTypeFragment
      rooms {
        ...RoomFragment
      }
    }
  }
`

export default function RoomAvailabilityModal({ isOpen, onDismiss }) {
  const location = useLocation()

  const { pathname, search } = location

  const params = queryString.parse(location.search)

  const identifier = params.room

  const accommodationQuery = useStaticQuery(CONTENTFUL_ROOMS)

  const { startDate, endDate, resetDates, niceStartDate, niceEndDateMinusDay } =
    useAppContext()

  const { northRooms, southRooms, bunkhouses } = accommodationQuery

  const joinedRooms = [...northRooms.rooms, ...southRooms.rooms]

  const rooms = [...joinedRooms, ...bunkhouses.rooms].filter(rejectJordan)

  const { data } = useRoomAvailability({ startDate, endDate })

  const resources = pathOr([], ['resources'], data)
  const filteredResources = resources.filter(rejectJordan)

  useEffect(() => {
    resetDates()
  }, [pathname])

  const mappedRooms = rooms
    .map((room) => {
      const matchedRoom = filteredResources.find(
        (resource) => resource.identifier === room.identifier
      )
      return { ...room, ...matchedRoom }
    })
    .filter(rejectJordan)

  const activeRoom = mappedRooms.find((room) => room.identifier === identifier)

  const sortedRooms = compose(
    reject(isNil),
    when(() => Boolean(activeRoom), prepend(activeRoom)),
    reject((room) => room.identifier === activeRoom?.identifier)
  )(mappedRooms)

  const noResources = isEmpty(filteredResources)
  const hasData = notEmptyOrNil(data)

  const hasNoBookableResources = hasData && noResources

  const notNil = complement(isNil)

  const hasDates = all(notNil, [startDate, endDate])

  const { data: costData } = useBookingCost({
    start: niceStartDate,
    end: niceEndDateMinusDay,
    resourceIds: sortedRooms.map((resource) => resource.id),
  })

  const resourceCosts = costData?.calculateItemisedCost?.resources ?? []

  return (
    <RoomModal isOpen={isOpen} onDismiss={onDismiss}>
      <Box>
        <Box mb="m">
          <Heading variant="heading.l">Select dates</Heading>

          <Divider my="s" />
        </Box>

        <Grid columns={[1, null, 2]} gap={['l', null, null, 'm']}>
          <Box
            sx={{
              boxShadow: [null, '0 5px 10px rgba(0, 0, 0, 0.05)'],
              borderRadius: 10,
              border: [null, 'solid 1px'],
              borderColor: [null, 'black.5'],
              padding: [0, 'm'],
              alignSelf: 'flex-start',
            }}
          >
            <Flex mb="s" sx={{ justifyContent: 'space-between' }}>
              <Text variant="subhead">Choose Dates</Text>
              {hasDates && (
                <Text
                  variant="subhead"
                  onClick={resetDates}
                  sx={{ color: 'black.50', cursor: 'pointer' }}
                >
                  Clear dates
                </Text>
              )}
            </Flex>

            <Box sx={{ marginBottom: 's' }}>
              <Calendar />
            </Box>
            <Box>
              <RoomAvailabilityButtons />
            </Box>
          </Box>
          <Box sx={{ marginY: hasNoBookableResources ? 'auto' : null }}>
            {hasNoBookableResources ? (
              <Box sx={{ maxWidth: 280, marginX: 'auto', textAlign: 'center' }}>
                Unfortunately we have no rooms available for these dates.
              </Box>
            ) : (
              <Grid columns={[1]} sx={{ gap: [10, null, 's'] }}>
                {sortedRooms.map((resource, index) => {
                  return (
                    <RoomBookingInfo
                      roomCost={resourceCosts.find(
                        (room) => room.identifier === resource.identifier
                      )}
                      room={resource}
                      key={resource.id}
                      startDate={startDate}
                      endDate={endDate}
                      resetDates={resetDates}
                      preferredRoom={Boolean(index === 0 && activeRoom)}
                      hasPreferredRoom={Boolean(activeRoom)}
                      index={index}
                    />
                  )
                })}
              </Grid>
            )}
          </Box>
        </Grid>
      </Box>
    </RoomModal>
  )
}
