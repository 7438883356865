import { useQuery } from '@apollo/client'
import { CALCULATE_COST } from 'queries/rooms'
import { notEmptyOrNil } from 'utils/utils'

export default function useBookingCost({
  start,
  end,
  resourceIds,
  skipQuery,
  extras = [],
}) {
  const filteredResourceIds = resourceIds.filter((resourceId) => {
    const res = /^\d+$/g.test(resourceId)

    return res
  })

  const hasResources = notEmptyOrNil(filteredResourceIds)

  const shouldSkipQuery = !hasResources || skipQuery || !start || !end

  return useQuery(CALCULATE_COST, {
    variables: {
      booking: {
        extras,
        resources: filteredResourceIds.map((resourceId) => ({
          id: Number(resourceId),
          start,
          end,
          quantity: 1,
        })),
      },
    },
    skip: shouldSkipQuery,
  })
}
