import React, { useState, createContext, useContext } from 'react'

import { maybeMinusDay } from './dates'

export const AppContext = createContext({
  modalOpen: false,
  onOpen: () => void 0,
  onDismiss: () => void 0,
  modalContent: null,
  setModalContent: () => void 0,
})

export function AppContextProvider(props) {
  const { children } = props

  const [showDialog, setShowDialog] = useState(false)
  const onOpen = () => setShowDialog(true)
  const onDismiss = () => setShowDialog(false)

  const [currentModal, setCurrentModal] = useState(null)

  const initialState = {
    startDate: null,
    endDate: null,
  }

  const [{ startDate, endDate }, setDates] = useState(initialState)

  const [niceDates, setNiceDates] = useState(initialState)

  const { niceStartDate, niceEndDate } = niceDates

  const resetDates = () => {
    setNiceDates(initialState)
    setDates(initialState)
  }

  return (
    <AppContext.Provider
      value={{
        isOpen: showDialog,
        onDismiss,
        onOpen,
        startDate,
        endDate,
        setDates,
        niceStartDate,
        niceEndDate,
        niceEndDateMinusDay: maybeMinusDay(niceEndDate),
        setNiceDates,
        resetDates,
        currentModal,
        setCurrentModal,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export function useAppContext() {
  return useContext(AppContext)
}
