/** @jsx jsx */
import { jsx, Flex, Box, Heading, Text, Grid } from 'theme-ui'
import { useAppContext, toPounds } from 'utils'
import { Link } from 'gatsby'
import qs from 'query-string'
import { split } from 'ramda'

import ArrowIcon from 'images/svg/right-arrow-circle.svg'
import RoomMeta from './room-meta'

const mergeDisplayDate = (date, availability) => {
  const dateISOString = date.toISOString()

  const [, splitTime] = split('T', availability)
  const [splitDate] = split('T', dateISOString)

  const mergedDateTime = `${splitDate}T${splitTime}`

  return mergedDateTime
}

const getArgs = ({
  room,
  startDate,
  endDate,
  firstAvailability,
  lastAvailability,
}) => {
  return qs.stringify({
    id: room.id,
    start: mergeDisplayDate(startDate, firstAvailability),
    end: mergeDisplayDate(endDate, lastAvailability),
    identifier: room.identifier,
  })
}

export default function RoomBookingInfo(props) {
  const { room, hasPreferredRoom, preferredRoom, index, roomCost } = props

  const { niceStartDate, niceEndDateMinusDay } = useAppContext()

  if (!roomCost) {
    return null
  }

  const args = getArgs({
    room,
    startDate: niceStartDate,
    endDate: niceEndDateMinusDay,
    firstAvailability: room.firstAvailability,
    lastAvailability: room.lastAvailability,
  })

  return (
    <Grid gap={10}>
      {preferredRoom && <Text variant="subhead">Preferred Room</Text>}

      {hasPreferredRoom && index === 1 && (
        <Text variant="subhead">Other Rooms</Text>
      )}

      <a
        href={`${process.env.GATSBY_BOOKING_APP_URL}/rooms/${room.identifier}/book?${args}`}
        sx={{
          padding: ['s'],
          color: 'black.100',
          textDecoration: 'none',
          boxShadow: 'xs',
          borderRadius: 4,
          transition: 'all .2s ease',
          '@media (hover: hover)': {
            '&:hover': {
              boxShadow: 's',
            },
          },
        }}
      >
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Box>
            <Grid gap={8}>
              <Text>{room.name}</Text>
              <Box sx={{ color: 'black.60' }}>
                <RoomMeta room={room} />
              </Box>
            </Grid>
          </Box>

          <Box>
            <Grid
              sx={{
                gap: 'xs',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'flex-end ',
              }}
            >
              <RoomCost roomCost={roomCost?.blockCost} />
              <ArrowIcon
                sx={{ display: 'block', marginLeft: 'auto' }}
                width={24}
                height={24}
              />
            </Grid>
          </Box>
        </Flex>
      </a>
    </Grid>
  )
}

function RoomCost({ roomCost }) {
  if (!roomCost) {
    return null
  }
  return <Text>{toPounds(roomCost / 100)}</Text>
}
