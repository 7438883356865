/** @jsx jsx */
import {
  jsx,
  Box,
  Flex,
  Grid,
  Text,
  NavLink,
  Link as ThemeLink,
  IconButton,
} from 'theme-ui'
import { Link } from 'gatsby'
import React, { useState, useEffect } from 'react'
import { useBreakpointIndex } from '@theme-ui/match-media'
import { useLocation, useLockBodyScroll } from 'react-use'

import PhoneIcon from '../../static/images/svg/phone-icon.svg'
import OpenNav from '../../static/images/svg/open-nav.svg'
import CloseNav from '../../static/images/svg/close-nav.svg'
import Facebook from '../../static/images/svg/facebook.svg'
import Logo from '../../static/images/svg/logo.svg'

Header.defaultProps = {
  links: [
    { children: 'Food & Drink', to: '/food-and-drink' },
    { children: 'Accommodation', to: '/accommodation' },
    { children: 'Motor Homes', to: '/motor-homes-camper-vans' },
    { children: 'Our History', to: '/our-history' },
    { children: 'Nearby', to: '/nearby' },
    { children: 'Events', to: '/events' },
    { children: 'Gallery', to: '/gallery' },
    { children: 'Contact', to: '/contact' },
  ],
}

export function ContactInfo() {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: ['space-between', null, null, 'flex-end'],
      }}
    >
      <Flex>
        <PhoneIcon style={{ display: 'block' }} />

        <ThemeLink
          variant="inline"
          href="tel:01524 241274"
          ml="xs"
          sx={{ fontSize: 'inherit' }}
        >
          01524 241274
        </ThemeLink>
      </Flex>

      <Box
        ml={'s'}
        as="a"
        target="_blank"
        href="https://www.facebook.com/thestationinnribblehead/"
        sx={{ textDecoration: 'none' }}
      >
        <Facebook style={{ display: 'block' }} width={24} height={24} />
      </Box>
    </Flex>
  )
}

export function Overlay(props) {
  const { links } = props

  const overlayStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    bg: 'white.100',
    zIndex: 999,
    flexDirection: 'column',
    p: 'm',
  }

  return (
    <Flex sx={overlayStyles}>
      <Flex
        sx={{
          pt: 'xxl',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        {links.map((link) => (
          <NavLink
            as={Link}
            key={link.to}
            {...link}
            variant="mobile"
            sx={{ marginBottom: 2, letterSpacing: '-0.02em' }}
          />
        ))}
      </Flex>
      <ContactInfo />
    </Flex>
  )
}

function MobileNavBar(props) {
  const { setOpen, isOpen } = props

  useLockBodyScroll(isOpen)

  return (
    <Box py="s" px="m" sx={{ position: 'relative', zIndex: 1000 }}>
      <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ width: [200], display: 'block' }}>
          <Link to="/" style={{ display: 'block' }}>
            <Logo style={{ display: 'block', width: 200 }} />
          </Link>
        </Box>

        <Flex
          sx={{ alignItems: 'center', cursor: 'pointer' }}
          onClick={() => setOpen(!isOpen)}
        >
          <Text
            mr="xs"
            variant="stnd.xxs"
            sx={{
              textTransform: 'uppercase',
              letterSpacing: 1,
              fontWeight: 'semibold',
            }}
          >
            Menu
          </Text>

          <IconButton>{isOpen ? <CloseNav /> : <OpenNav />}</IconButton>
        </Flex>
      </Flex>
    </Box>
  )
}

const isLastItem = (items, index) => index + 1 >= items.length

export function Header(props) {
  const { links } = props
  const index = useBreakpointIndex()
  const [isOpen, setOpen] = useState(false)

  const { pathname } = useLocation()

  useEffect(() => {
    setOpen(false)
  }, [pathname])

  if (index <= 1) {
    return (
      <>
        {isOpen && <Overlay links={links} />}
        <MobileNavBar setOpen={setOpen} isOpen={isOpen} />
      </>
    )
  }

  return (
    <Box
      px={'m'}
      py={'s'}
      sx={{ boxShadow: 'm', position: 'relative', zIndex: 2 }}
    >
      <Box
        sx={{
          display: [null, null, 'flex'],
          flexDirection: ['column', null, null, 'row'],
          justifyContent: [null, null, 'space-between'],
          alignItems: ['stretch'],
        }}
      >
        <Flex
          mr={[null, null, null, 'm']}
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Link to="/" style={{ display: 'block' }}>
            <Logo style={{ display: 'block' }} />
          </Link>

          {index <= 2 && (
            <Box sx={{ fontSize: 'xxs' }}>
              <ContactInfo />
            </Box>
          )}
        </Flex>

        <Box
          sx={{
            display: [null, null, 'flex'],
            alignItems: 'center',
            pt: ['m', null, 's', 0],
            mx: [null, null, null, 'auto'],
          }}
        >
          {links.map((link, index) => {
            return (
              <NavLink
                as={Link}
                key={link.to}
                sx={{
                  marginRight: isLastItem(links, index)
                    ? null
                    : [null, null, 16],
                }}
                {...link}
              />
            )
          })}
        </Box>

        {index > 2 && (
          <Box sx={{ fontSize: 'xxs' }}>
            <ContactInfo />
          </Box>
        )}
      </Box>
    </Box>
  )
}
