/** @jsx jsx */
import { jsx, Grid, Box, Heading, Button, Divider } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from 'react-use'
import queryString from 'query-string'

import { rejectJordan } from 'utils/utils'

import RoomMeta from './room-meta'
import RoomModal from './room-modal'

import { RoomDetails } from './rooms'
import { renderContent } from 'utils/render-content'
import { useAppContext } from 'utils/app-context'

const CONTENTFUL_ROOMS = graphql`
  query RoomsQueryOne {
    northRooms: contentfulRoomType(slug: { eq: "north-facing" }) {
      ...RoomTypeFragment
      rooms {
        ...RoomFragment
      }
    }
    southRooms: contentfulRoomType(slug: { eq: "south-facing" }) {
      ...RoomTypeFragment
      rooms {
        ...RoomFragment
      }
    }
    bunkhouses: contentfulRoomType(slug: { eq: "bunkhouses" }) {
      ...RoomTypeFragment
      rooms {
        ...RoomFragment
      }
    }
  }
`

export default function RoomModalInfo(props) {
  const { isOpen, onDismiss } = props

  const { setCurrentModal } = useAppContext()

  const location = useLocation()

  const params = queryString.parse(location.search)

  const identifier = params.room

  const accommodationQuery = useStaticQuery(CONTENTFUL_ROOMS)

  const { northRooms, southRooms, bunkhouses } = accommodationQuery

  const joinedRooms = [...northRooms.rooms, ...southRooms.rooms]

  const rooms = [...joinedRooms, ...bunkhouses.rooms].filter(rejectJordan)

  const activeRoom = rooms.find((room) => room.identifier === identifier)

  return (
    <RoomModal sx={{ maxWidth: 680 }} isOpen={isOpen} onDismiss={onDismiss}>
      <Box>
        <Box mb="m">
          <Heading variant="heading.l">{activeRoom?.name}</Heading>

          <Divider my="s" />
        </Box>

        {activeRoom && (
          <Grid gap="m" columns={[1, null, '240px auto']}>
            <Box>
              <RoomDetails room={activeRoom} />
            </Box>

            <Grid gap="m">
              <Box>{renderContent(JSON.parse(activeRoom.description.raw))}</Box>
              <Button
                // variant="square"
                onClick={() => setCurrentModal('availability')}
              >
                Select dates
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </RoomModal>
  )
}
