/** @jsx jsx */
import { jsx, Flex, Box, Text, Button } from 'theme-ui'
import { Link } from 'gatsby'

RoomAvailabilityButtons.defaultProps = {
  identifier: 'salt-lake',
}

export default function RoomAvailabilityButtons() {
  return (
    <Box>
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text variant="stnd.body" sx={{ color: 'black.50' }}>
          Select your preferred dates
        </Text>
      </Flex>
    </Box>
  )
}
