import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from 'react-use'

SEO.defaultProps = {
  image: 'https://station-inn.s3.eu-west-2.amazonaws.com/video-cover.jpg',
}

export function SEO(props) {
  const { title, description, path, image } = props

  const { pathname } = useLocation()

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          baseUrl
        }
      }
    }
  `)

  const defaults = data.site.siteMetadata

  if (defaults.baseUrl === '' && typeof window !== 'undefined') {
    defaults.baseUrl = window.location.origin
  }

  if (defaults.baseUrl === '') {
    console.error('Please set a baseUrl in your site metadata!')
    return null
  }

  const desc = description || defaults.description
  const url = new URL(pathname || '', defaults.baseUrl)
  const pageTitle = title ? `${title} — ${defaults.title}` : defaults.title

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={desc} />
      {image && <meta name="image" content={image} />}

      <meta property="og:url" content={url} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={desc} />
      {image && <meta property="og:image" content={image} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={desc} />
      {image && <meta name="twitter:image" content={image} />}
    </Helmet>
  )
}
